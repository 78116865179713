import React from 'react';
import styled from 'styled-components';
import theme from '../theme/theme';

export default function Header() {
  return (
    <HeaderWrap>
      <h1>누구나 쉽게! 언제나 편리하게!</h1>
      <h2>신분증 마스킹</h2>
    </HeaderWrap>
  );
}

const HeaderWrap = styled.header`
  color: ${theme.blue};
  text-align: center;
  margin-bottom: 35px;
  font-weight: 700;
  padding-top: 5px;
  h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 57px;
  }
  &:active {
    & h1,
    h2 {
      opacity: 0.5;
    }
  }
`;
