import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const ResetStyles = createGlobalStyle`
  ${reset}
  *{
    box-sizing: border;
  }
/* 
  html,body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
    color: inherit;
    flex-shrink: 0;
  }
  ul,li{
    list-style: none;
    margin: 0;
    padding: 0;
  } */
`;

export default ResetStyles;
