import React from 'react';
import styled from 'styled-components';
import { Span } from '../style/CommonStyles';
import theme from '../theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import { changeIdCardType } from '../store/idCardTypeSlice';

export default function SelectIdCard() {
  const idCardType = useSelector(state => state.idCardType);

  const dispatch = useDispatch();

  const onChangeIdCardType = e => {
    dispatch(changeIdCardType(e.target.value));
  };

  return (
    <>
      <Span>
        <div></div>
        <span>신분증 선택</span>
      </Span>
      <SelectIdCardWrap>
        <input
          type="radio"
          name="idCard"
          value="0"
          id="personId"
          onChange={onChangeIdCardType}
          checked={idCardType === '0'}
          hidden
        />
        <label htmlFor="personId">주민등록증</label>
        <input
          type="radio"
          name="idCard"
          value="1"
          id="driverId"
          onChange={onChangeIdCardType}
          checked={idCardType === '1'}
          hidden
        />
        <label htmlFor="driverId">운전면허증</label>
      </SelectIdCardWrap>
    </>
  );
}

const SelectIdCardWrap = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    border-radius: 13px;
    border: 1px solid ${theme.gray};
    color: ${theme.gray};
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 900;
    width: calc(50% - 10px);
    text-align: center;
  }
  input[type='radio']:checked + label {
    background-color: ${theme.blue};
    color: white;
  }
`;
