import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import UserInfo from './UserInfo';
import Manual from './Manual';
import { FiImage } from 'react-icons/fi';
import theme from 'theme/theme';
import ImageCompression from 'browser-image-compression';
import axios from 'axios';

const BASE_URL = 'http://localhost:8000';

export default function ImageUploadForm() {
  const [image, setImage] = useState({
    fileInfo: '',
    preview_URL: '',
  });

  const onChangeImage = e => {
    let imgFile = e.target.files[0];
    // 이미지 크기가 1mb이상일때 압축 후 저장
    if (imgFile.size > 1000000) {
      const options = {
        maxSizezMB: 1,
        useWebWorker: true,
      };
      imgFile = ImageCompression(imgFile, options);
    }

    if (imgFile) {
      // 새로운 이미지를 올리면 createObjectURL()을 통해 생성한 기존 URL을 폐기
      URL.revokeObjectURL(image.preview_URL);
      const preview_URL = URL.createObjectURL(imgFile);
      setImage(() => ({
        fileInfo: imgFile,
        preview_URL: preview_URL,
      }));
    }
  };

  const resetImage = () => {
    // createObjectURL()을 통해 생성한 기존 URL을 폐기
    URL.revokeObjectURL(image.preview_URL);
    setImage({
      fileInfo: '',
      preview_URL: '',
    });
  };

  const onSubmitImage = async e => {
    e.preventDefault();
    if (image.fileInfo) {
      const formData = new FormData();
      formData.append('file', image.fileInfo);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const result = await axios.post(`${BASE_URL}/upload`, formData, config);
      console.log(result);
    }
  };

  useEffect(() => {
    return () => {
      // 컴포넌트가 언마운트되면 createObjectURL()을 통해 생성한 기존 URL을 폐기
      URL.revokeObjectURL(image.preview_URL);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={onSubmitImage}>
      <input
        type="file"
        id="imageUpload"
        multiple={false}
        accept=".jpg, .jpeg, .png"
        hidden
        onChange={onChangeImage}
        onClick={e => (e.target.value = null)}
      />
      <ImageUploadLabel htmlFor="imageUpload">
        {image.preview_URL === '' ? (
          <div>
            <FiImage color={'#1473E6'} size={85} />
            <p>신분증 이미지 업로드</p>
          </div>
        ) : (
          <div>
            <img
              src={image.preview_URL}
              alt="img"
              style={{ maxWidth: '100%' }}
            />
          </div>
        )}
      </ImageUploadLabel>
      <p style={{ color: `${theme.gray}`, fontSize: '14px' }}>
        * 업로드된 신분증 정보는 저장되지 않고 자동적으로 파기 됩니다.
      </p>
      <Manual />
      <UserInfo />
      <UploadButtonWrap>
        <button>개인정보 마스킹</button>
        {/* <button type="button">이미지 저장하기</button> */}
        <button type="button" onClick={resetImage}>
          초기화
        </button>
      </UploadButtonWrap>
    </form>
  );
}

const ImageUploadLabel = styled.label`
  border: 1px solid ${theme.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 30px;
  padding-top: 90px;
  padding-bottom: 80px;
  color: ${theme.blue};
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 450px;
    max-height: 300px;
    p {
      font-size: 17px;
      margin: 5px 0 21px;
    }
  }
`;

const UploadButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    border: 1px solid ${theme.gray};
    color: ${theme.gray};
    background-color: transparent;
    border-radius: 13px;
    padding: 16px 0;
    font-weight: 900;
    width: calc(50% - 10px);
    font-size: 16px;
  }
  button:active {
    background-color: ${theme.blue};
    color: white;
  }
`;
