import styled from 'styled-components';

export const Span = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  div {
    width: 16px;
    height: 16px;
    background-color: black;
    margin-right: 8px;
    background-color: rgb(20, 115, 230);
  }
  span {
    font-size: 18px;
    margin-top: 2px;
  }
`;
