import { configureStore } from '@reduxjs/toolkit';
import idCardTypeSlice from './idCardTypeSlice';
import UserInfoSlice from './UserInfoSlice';

const store = configureStore({
  reducer: {
    idCardType: idCardTypeSlice.reducer,
    userInfo: UserInfoSlice.reducer,
  },
});

export default store;
