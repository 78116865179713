import React from 'react';
import styled from 'styled-components';
import Header from 'components/Header';
import ImageUploadForm from 'components/ImageUploadForm';
import SelectIdCard from 'components/SelectIdCard';

export default function ImageMaskingPage() {
  return (
    <ImageMaskingWrap>
      <Header />
      <SelectIdCard />
      <ImageUploadForm />
    </ImageMaskingWrap>
  );
}

const ImageMaskingWrap = styled.div`
  min-height: 100vh;
  padding: 0 35px;
  margin-top: 50px;
`;
