import React from 'react';
import styled from 'styled-components';
import { Span } from '../style/CommonStyles';
import theme from '../theme/theme';
import { useSelector } from 'react-redux';

export default function UserInfo() {
  const idCardType = useSelector(state => state.idCardType);
  const userInfo = useSelector(state => state.userInfo);

  return (
    <>
      <Span>
        <div></div>
        <span>정보확인</span>
      </Span>
      <InfoList>
        <li>
          <div>유형</div>
          <div style={{ color: `${theme.blue}` }}>
            {idCardType === '0' ? '주민등록증' : '운전면허증'}
          </div>
        </li>
        <li>
          <div>주민번호</div>
          <div>{userInfo.personalNumber}</div>
        </li>
        <li>
          <div>주소</div>
          <div>{userInfo.address}</div>
        </li>
        <li>
          <div>발급일자</div>
          <div>{userInfo.date}</div>
        </li>
        <li>
          <div>진위여부</div>
          <div>{userInfo.trueFalse}</div>
        </li>
        {/* 얼굴인식기능? */}
      </InfoList>
    </>
  );
}
const InfoList = styled.ul`
  margin-bottom: 20px;
  border-top: 1px solid ${theme.gray};
  border-left: 1px solid ${theme.gray};
  border-right: 1px solid ${theme.gray};

  li {
    display: flex;
    align-content: center;
    border-bottom: 1px solid ${theme.gray};

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div:first-child {
      padding: 12px 0;
      border-right: 1px solid ${theme.gray};
      flex: 2 1 0%;
    }
    div:last-child {
      word-break: break-all;
      color: black;
      flex: 8 1 0%;
    }
  }
`;
