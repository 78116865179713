import { createSlice } from '@reduxjs/toolkit';

const initialState = '0';

const idCardTypeSlice = createSlice({
  name: 'idCardType',
  initialState,
  reducers: {
    changeIdCardType: (state, action) => {
      return action.payload;
    },
  },
});

export default idCardTypeSlice;
export const { changeIdCardType } = idCardTypeSlice.actions;
