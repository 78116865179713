import React from 'react';
import styled from 'styled-components';
import theme from '../theme/theme';

export default function Manual() {
  return (
    <ManualWrap>
      <ManualList>
        <ManualListCircle>1</ManualListCircle>
        <p>
          신분증 촬영 시, 신분증의 앞면이 보이도록 놓아주세요. 어두운 바닥에
          놓으면 더 잘 인식됩니다.
        </p>
      </ManualList>
      <ManualList>
        <ManualListCircle>2</ManualListCircle>
        <p>가이드 영역에 맞추어 신분증을 촬영하세요.</p>
      </ManualList>
      <ManualList>
        <ManualListCircle>3</ManualListCircle>
        <p>
          신분증 촬영 시, 빛이 반사되지 않도록 주의하세요. 훼손이 심한 신분증은
          인식이 되지 않을 수 있습니다.
        </p>
      </ManualList>
    </ManualWrap>
  );
}

const ManualWrap = styled.ul`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
`;

const ManualList = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  p {
    color: black;
    word-break: break-all;
    font-size: 14px;
    font-weight: 900;
  }
`;

const ManualListCircle = styled.div`
  background-color: ${theme.blue};
  border-radius: 50%;
  max-width: 26px;
  min-width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 13px;
  font-size: 14px;
`;
