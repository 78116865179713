import { Route, Routes } from 'react-router-dom';
import ImageMaskingPage from './pages/ImageMaskingPage';
import ResetStyles from './style/ResetStyles';

function App() {
  return (
    <>
      <ResetStyles />
      <Routes>
        <Route path="/" element={<ImageMaskingPage />} />
      </Routes>
    </>
  );
}

export default App;
