import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  personalNumber: '-',
  address: '-',
  date: '-',
  trueFalse: '-',
};

const UserInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {},
});

export default UserInfoSlice;
export const {} = UserInfoSlice.actions;
